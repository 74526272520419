import {
  CREATE_CAMPAIGN,
  UPDATE_CAMPAIGN,
  UNLOAD_CAMPAIGN,
  CAMPAIGN_FETCHED_SUCCESS,
  RESET_CAMPAIGN,
  CAMPAIGN_TOGGLE_FAILED,
  CAMPAIGN_TOGGLE_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  campaigns: [],
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CAMPAIGN:
    case UPDATE_CAMPAIGN:
      return {
        ...state,
        message: action.payload.msg,
      };

    case UNLOAD_CAMPAIGN:
      return initialState;

    case CAMPAIGN_FETCHED_SUCCESS:
      return {
        ...state,
        campaigns: action.payload.campaigns.map((item) => {
          const newItem = {
            ...item,
          };
          return newItem;
        }),
        pager: action.payload.pager,
      };

    case RESET_CAMPAIGN:
      return {
        ...state,
        message: '',
      };

    case CAMPAIGN_TOGGLE_FAILED:
      return state;

    case CAMPAIGN_TOGGLE_SUCCESS:
      return {
        ...state,
        campaigns: state.campaigns.map((item) => {
          if (item.id !== action.payload.id) return item;
          const newItem = { ...item };
          newItem.is_active = action.payload.active;
          return newItem;
        }),
      };

    default:
      return state;
  }
};
