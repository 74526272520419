import {
  REMOTE_CONFIG_FETCHED_SUCCESS,
  UNLOAD_REMOTE_CONFIG,
  ADD_REMOTE_CONFIG,
  UPDATE_REMOTE_CONFIG,
  PUSHED_REMOTE_CONFIG,
} from '../constants/actionTypes';

const initialState = {
  remoteConfigs: [],
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_REMOTE_CONFIG:
    case UPDATE_REMOTE_CONFIG:
      return {
        ...state,
        message: action.payload.msg,
      };

    case UNLOAD_REMOTE_CONFIG:
      return initialState;

    case REMOTE_CONFIG_FETCHED_SUCCESS:
      return {
        ...state,
        remoteConfigs: action.payload.remoteConfigs.map((item) => {
          const newItem = {
            ...item,

            id: item.id,
          };
          return newItem;
        }),
        pager: action.payload.pager,
      };

    case PUSHED_REMOTE_CONFIG:
      return {
        ...state,
        remoteConfigs: state.remoteConfigs.map((item) => {
          if (item.id === action.payload.id) {
            const newItem = { ...item };
            newItem.pushed_at = action.payload.date;
            return newItem;
          }
          return item;
        }),
      };

    default:
      return state;
  }
};
