import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const EditButton = ({
  permission, record,
}) => (
  <Button
    type={
      record.active
      || record.status === 'ACTIVE'
      || record.status === 'RESOLVED'
        ? 'primary' : 'dashed'
    }
    size="small"
    icon={<EditOutlined />}
    title="View/Edit"
    disabled={!permission.view}
  />
);

EditButton.propTypes = {
  permission: PropTypes.shape({
    view: PropTypes.bool,
  }).isRequired,

  record: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  permission: ownProps.permissionKey
    ? state.common.user.permissions[ownProps.permissionKey]
    : { view: true },
  ...ownProps,
});

export default connect(mapStateToProps)(EditButton);
