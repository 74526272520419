import { JWT_ACCESS_TOKEN, JWT_REFRESH_TOKEN } from '../constants/auth';

const setAccesTokenToStorage = (token, expiry) => {
  window.localStorage.setItem(JWT_ACCESS_TOKEN, token);
  window.localStorage.setItem('expiry', expiry * 1000);
  window.localStorage.setItem('issuedAt', Date.now());
};

const setRefreshTokenToStorage = (token) => {
  window.localStorage.setItem(JWT_REFRESH_TOKEN, token);
};

const clearAccesTokenFromStorage = () => {
  window.localStorage.removeItem(JWT_ACCESS_TOKEN);
  window.localStorage.removeItem('expiry');
  window.localStorage.removeItem('issuedAt');
};

const clearRefreshTokenFromStorage = () => {
  window.localStorage.removeItem(JWT_REFRESH_TOKEN);
};

const getAccessTokenDataFromStorage = () => ({
  token: window.localStorage.getItem(JWT_ACCESS_TOKEN),
  expiry: Number(window.localStorage.getItem('expiry')),
  issuedAt: Number(window.localStorage.getItem('issuedAt')),
});

const getRefreshTokenFromStorage = () => ({
  token: window.localStorage.getItem(JWT_REFRESH_TOKEN),
});

const isAccessTokenExpired = (expiry, issuedAt) => (Date.now() > (issuedAt + expiry));

export default {
  setAccesToken: setAccesTokenToStorage,
  setRefreshToken: setRefreshTokenToStorage,
  clearAccesToken: clearAccesTokenFromStorage,
  clearRefreshToken: clearRefreshTokenFromStorage,
  getAccesTokenInfo: getAccessTokenDataFromStorage,
  getRefreshTokenInfo: getRefreshTokenFromStorage,
  isTokenExpired: isAccessTokenExpired,
};
