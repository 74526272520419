import {
  CREATE_BILLER,
  UPDATE_BILLER,
  UNLOAD_BILLER,
  BILLER_FETCHED_SUCCESS,
  RESET_BILLER,
} from '../../constants/actionTypes';

const initialState = {
  biller: [],
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_BILLER:
    case UPDATE_BILLER:
      return {
        ...state,
        message: action.payload.msg,
      };

    case RESET_BILLER:
      return {
        ...state,
        message: '',
      };

    case BILLER_FETCHED_SUCCESS:
      return {
        ...state,
        biller: action.payload.biller.map((item) => {
          const newItem = { ...item, id: item.id };
          return newItem;
        }),
        pager: action.payload.pager,
      };

    case UNLOAD_BILLER:
      return initialState;

    default:
      return state;
  }
};
