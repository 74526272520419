/* eslint-disable import/prefer-default-export */
import {
  LOGIN, LOGOUT, LOGIN_SUCCESSFULL, LOGGED_IN, LOGGED_OUT, LOGIN_FAILED,
} from '../constants/actionTypes';
import agent from '../../agent';
/*
 * action creators
 */

// attempt login
export function login(email, password) {
  return { type: LOGIN, payload: agent.Auth.login(email, password) };
}

// user attempt for login is successfull
export function loginSuccessfull() {
  return { type: LOGIN_SUCCESSFULL };
}

// user attempt for login is failed
export function loginFailed() {
  return { type: LOGIN_FAILED };
}

// if user is already logged in to system
export function loggedIn() {
  return { type: LOGGED_IN };
}

// user wants to logout
export function logout() {
  return { type: LOGOUT };
}

// user has been forcefully logged out
export function forceLogout() {
  return { type: LOGGED_OUT };
}
