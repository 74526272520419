import {
  CREATE_CPAT_CAMPAIGN,
  UPDATE_CPAT_CAMPAIGN,
  UNLOAD_CPAT_CAMPAIGN,
  RESET_CPAT_CAMPAIGN,
  CPAT_CAMPAIGN_TOGGLE_SUCCESS,
  CPAT_CAMPAIGN_FETCHED_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  cpat_campaigns: [],
  errors: {},
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CPAT_CAMPAIGN:
      return {
        ...state,
      };
    case UPDATE_CPAT_CAMPAIGN:
      return {
        ...state,
        errors: action.payload.errors,
        message: action.payload.msg,
      };

    case UNLOAD_CPAT_CAMPAIGN:
      return initialState;

    case CPAT_CAMPAIGN_FETCHED_SUCCESS:
      return {
        ...state,
        cpat_campaigns: action.payload.cpatCampaigns.map((item) => {
          const newItem = { ...item, id: item.id };
          return newItem;
        }),
        pager: action.payload.pager,
      };

    case RESET_CPAT_CAMPAIGN:
      return {
        ...state,
        message: '',
      };

    case CPAT_CAMPAIGN_TOGGLE_SUCCESS:
      return {
        ...state,
        cpat_campaigns: state.cpat_campaigns.map((item) => {
          if (item.id !== action.payload.id) return item;
          const newItem = { ...item };
          newItem.is_active = action.payload.active;
          return newItem;
        }),
      };

    default:
      return state;
  }
};
