import { OFFLINE_SALE_REPORTS_FETCHED_SUCCESS } from '../constants/actionTypes';

const initialState = {
  sales_reports: [],
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
  role: {
    message: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OFFLINE_SALE_REPORTS_FETCHED_SUCCESS:
      return {
        ...state,
        OfflineSales: action.payload.OfflineSales,
        pager: action.payload.pager,
      };

    default:
      return state;
  }
};
