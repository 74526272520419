/* eslint-disable import/prefer-default-export */
import { APP_LOADED, UPDATE_CURRENT_USER } from '../constants/actionTypes';

/*
 * action creators
 */

export function appLoaded(user = null) {
  return { type: APP_LOADED, user };
}

export function updateCurrentUser(user = null) {
  return { type: UPDATE_CURRENT_USER, user };
}

export function pushedResourceSuccessfully(id, date) {
  return (type) => ({ type, payload: { id, date } });
}
