export const optionMapper = (item) => ({ value: item, text: item });
export const bannerTypeOptions = ['DEEPLINK', 'OFFER'].map(optionMapper);

export const statusOptions = ['ACTIVE', 'DISABLED'].map(optionMapper);
export const isActiveOption = [
  { value: true, text: 'ACTIVE' },
  { value: false, text: 'DISABLED' },
];

export const isFlagOption = [
  { value: 1, text: 'ACTIVE' },
  { value: 0, text: 'DISABLED' },
];

export const offerLinkSourceOptions = [
  { value: 'AFFISE', text: 'Affise' },
  { value: 'SAPPHYRE', text: 'Sapphyre' },
];
export const disabledStatusOptions = ['DISABLED'].map(optionMapper);

export const redirectionFlagOptions = [
  { value: 'REFER_AND_EARN', text: 'REFER AND EARN' },
];

export const offerStatusOptions = [
  'ACTIVE',
  'PENDING',
  'PAUSED',
  'DISABLED',
].map(optionMapper);
export const fontFamilyOptions = ['MONTSERRAT', 'POPPINS'].map(optionMapper);
export const primeOfferStatusOptions = ['ACTIVE', 'PENDING', 'DISABLED'].map(
  optionMapper,
);
export const shareOfferClickStatusOptions = ['PENDING', 'COMPLETED', 'DECLINED'].map(
  optionMapper,
);

export const ticketResolutionOptions = [
  { value: true, text: 'IS_OFFLINE' },
  { value: false, text: 'PENDING' },
];

export const ctaTextOptions = [
  { value: 'TRY_NOW', text: 'TRY NOW' },
  { value: 'REGISTER_TODAY', text: 'REGISTER TODAY' },
];

export const primeOfferTagOptions = [
  { value: 'Popular', text: 'Popular' },
  { value: 'Exclusive', text: 'Exclusive' },
  { value: 'High Reward', text: 'High Reward' },
  { value: 'Referral', text: 'Referral' },
];

export const ctaTextOptionsArticles = [
  { value: 'KNOW_MORE', text: 'KNOW MORE' },
  { value: 'READ_MORE', text: 'READ MORE' },
  { value: 'CLICK_HERE', text: 'CLICK HERE' },
  { value: 'GET_STARTED', text: 'GET STARTED' },
  { value: 'LEARN_MORE', text: 'LEARN MORE' },
];
export const advertiserStatusOptions = ['ACTIVE', 'DISABLED', 'PAUSED'].map(
  optionMapper,
);
export const remoteConfigInputTypeOptions = [
  'NUMBER',
  'STRING',
  'BOOLEAN',
  'JSON',
].map(optionMapper);

export const categoryTypeOptions = ['PARENT', 'CUSTOM'].map(optionMapper);

export const goalTitleOptions = [
  { value: 'Sale', text: 'Sale' },
  { value: 'Leads', text: 'Leads' },
  { value: 'Signup', text: 'Signup' },
  { value: 'Signin', text: 'Signin' },
  { value: 'Register', text: 'Register' },
  { value: 'Application', text: 'Application' },
  { value: 'D0Sale', text: 'D0Sale' },
  { value: 'D7Sale', text: 'D7Sale' },
  { value: 'Install', text: 'Install' },
  { value: 'Shopper', text: 'Shopper' },
  { value: 'Listing', text: 'Listing' },
  { value: 'Load_Money', text: 'Load Money' },
  { value: 'Journey_Completion', text: 'Journey Completion' },
  { value: 'Transaction', text: 'Transaction' },
  { value: 'Loan_Approval', text: 'Loan Approval' },
  { value: 'Report_Received', text: 'Report Received' },
  { value: 'Genuine_Install', text: 'Genuine Install' },
  { value: 'Form_submission', text: 'Form submission' },
];

export const payoutTypeOptions = ['FLAT', 'PERCENTAGE'].map(optionMapper);

export const trackingTimeUnits = ['DAYS', 'HOURS', 'MINUTES'].map(optionMapper);

export const currencyOptions = ['INR', 'USD'].map(optionMapper);

export const offerTypeOption = ['CPS'].map(optionMapper);

export const offerTypeOptions = ['CPS', 'CPV', 'CPI', 'CPR', 'CPAT', 'CPC'].map(
  optionMapper,
);

export const primeOfferTypeOptions = ['CPL', 'CPAT', 'CPI', 'CPR'].map(
  optionMapper,
);

export const userBlockTypeOptions = ['EMAIL_ID', 'USER_ID', 'IP_ADDRESS'].map(
  optionMapper,
);

export const trafficSourceOptions = ['EMAIL', 'SMS', 'MOBILE_APP'].map(
  optionMapper,
);

export const clickParamsOptions = [
  'appname',
  'gaid',
  'idfa',
  'pid_subpid',
  'subparam1',
  'subparam2',
  'subparam3',
  'subparam4',
  'subparam5',
  'subparam6',
  'subparam7',
  'subparam8',
  'subpid',
];

export const ticketSourceOptions = ['CMS', 'APP'].map(optionMapper);
export const ticketTypeOptions = ['STORE', 'OFFER', 'CATEGORY'].map(
  optionMapper,
);
export const allTicketTypeOptions = ['STORE', 'OFFER', 'CATEGORY', 'APP'].map(
  optionMapper,
);

export const ticketPriorityOptions = ['HIGH', 'MEDIUM', 'LOW'].map(
  optionMapper,
);

export const ticketStatusOptions = [
  'RECEIVED',
  'IN_PROGRESS',
  'RESOLVED',
  'REJECT',
  'CLOSED',
].map(optionMapper);
export const reporterTicketOptions = ['PENDING', 'HOLD', 'CLOSE'].map(
  optionMapper,
);

export const pushTypeOptions = [
  'STORE',
  'OFFER',
  'CATEGORY',
  'SUB_CATEGORY',
  'TAG',
  'REMOTE_CONFIG',
  'CUSTOM_CATEGORY',
];

export const emailStatusOptions = ['ACTIVE', 'BLOCKED', 'DISABLED'].map(
  optionMapper,
);

export const emailVerificationOptions = ['VERIFIED', 'UNVERIFIED'].map(
  optionMapper,
);

export const userOfferTypeOptions = ['APP', 'WEBSITE'].map(optionMapper);

export const ticketTagOptions = [
  'SHOPPING',
  'KC_PRIME',
  'NEWS',
  'VIDEO',
  'MISC',
].map(optionMapper);

export const offerSourceOptions = [
  'ADMITAD',
  'VCOMMISSION',
  'FLIPKART',
  'OPTIMIZE',
  'PRIMEDIGITAL_KC',
  'BOAT',
].map(optionMapper);

export const callbackStatusOptions = ['PENDING', 'PROCESSED', 'DUPLICATE'].map(
  optionMapper,
);

export const additionalCashbackStatusOptions = [
  { value: 'PENDING', text: 'PENDING' },
  { value: 'APPROVED', text: 'APPROVED' },
  { value: 'DISCARDED', text: 'DISCARDED' },
];

export const couponOptions = ['PRESENT', 'ABSENT'].map(optionMapper);
export const couponStatusOptions = ['ENABLED', 'DISABLED'].map(optionMapper);

export const userReportOptions = [
  { value: true, text: 'COMPLETED' },
  { value: false, text: 'IN_PROGRESS' },
];
export const storeStatusOptions = ['ENABLED', 'DISABLED'].map(optionMapper);

export const isEarlySaleCashbackOption = [
  { value: true, text: 'ACTIVE' },
  { value: false, text: 'DISABLED' },
];

export const refundCashFreeStatusOptions = ['STARTED', 'SUCCESS', 'PENDING', 'CANCELLED', 'ONHOLD'].map(
  optionMapper,
);

export const refundStatusOptions = ['PENDING', 'DECLINED', 'APPROVED', 'HOLD'].map(
  optionMapper,
);

export const customCategoryOptions = ['FEATURED', 'SEASONAL', 'FLASH', 'PRODUCT', 'STORE'].map(
  optionMapper,
);

export const offersOptions = ['FEATURED', 'BANNER', 'SEASONAL', 'FLASH', 'PRODUCT', 'COUPON'].map(
  optionMapper,
);

export const couponTypeOptions = ['EXCLUSIVE', 'BANK'].map(
  optionMapper,
);

export const campaignOptions = ['ACTIVATION', 'RETENTION'].map(
  optionMapper,
);

export const targetingTypeOptions = ['REFERRED', 'UN_REFERRED'].map(
  optionMapper,
);

export const campaignStrategyOptions = ['EXTRA_CASHBACK', 'MULTIPLIER', 'EXPIRING_WALLET_CASHBACK'].map(
  optionMapper,
);

export const storeTypeOptions = ['D2C', 'MARKETPLACE'].map(
  optionMapper,
);

export const taskTypeOptions = ['TIME_BASED', 'GOAL_BASED'].map(
  optionMapper,
);

export const fileTypeOptions = ['PDF', 'IMAGE'].map(
  optionMapper,
);
