import {
  CREATE_ADDITIONAL_CASHBACK,
  UPDATE_ADDITIONAL_CASHBACK,
  UNLOAD_ADDITIONAL_CASHBACK,
  ADDITIONAL_CASHBACK_FETCHED_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  errors: {},
  additionalCashbacks: [],
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ADDITIONAL_CASHBACK:
    case UPDATE_ADDITIONAL_CASHBACK:
      return {
        ...state,
        errors: action.payload.errors,
        message: action.payload.msg,
      };

    case UNLOAD_ADDITIONAL_CASHBACK:
      return initialState;

    case ADDITIONAL_CASHBACK_FETCHED_SUCCESS:
      return {
        ...state,
        additionalCashbacks: action.payload.additionalCashbacks.map((item) => {
          const newItem = { ...item, id: item.id };
          return newItem;
        }),
        pager: action.payload.pager,
      };

    default:
      return state;
  }
};
