/*
  This is all the constants used in general
  Note: constants inside src/store folder is
        for actions only
 */

export const JWT_ACCESS_TOKEN = 'jwt_access';
export const JWT_REFRESH_TOKEN = 'jwt_refresh';

export default {
  JWT_ACCESS_TOKEN, JWT_REFRESH_TOKEN,
};
