import {
  CREATE_ADVERTISER,
  UPDATE_ADVERTISER,
  UNLOAD_ADVERTISER,
  ADVERTISER_FETCHED_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  advertisers: [],
  errors: {},
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ADVERTISER:
    case UPDATE_ADVERTISER:
      return {
        ...state,
        errors: action.payload.errors,
        message: action.payload.msg,
      };

    case UNLOAD_ADVERTISER:
      return initialState;

    case ADVERTISER_FETCHED_SUCCESS:
      return {
        ...state,
        advertisers: action.payload.advertisers.map((item) => {
          const newItem = { ...item, id: item.id };
          return newItem;
        }),
        pager: action.payload.pager,
      };

    default:
      return state;
  }
};
