import {
  PRIME_OFFER_CALLBACKS_FETCHED_SUCCESS,
  PRIME_OFFER_CALLBACK_FETCHING_SUCCESS,
  PRIME_OFFER_CALLBACK_FETCHING_FAILED,
} from '../constants/actionTypes';

const initialState = {
  prime_offer_callbacks: [],
  actionPool: [],
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PRIME_OFFER_CALLBACKS_FETCHED_SUCCESS:
      return {
        ...state,
        prime_offer_callbacks: action.payload.prime_offer_callbacks.map((item) => {
          const newItem = { ...item, id: item.id };
          return newItem;
        }),
        pager: action.payload.pager,
      };
    case PRIME_OFFER_CALLBACK_FETCHING_SUCCESS:
      return {
        ...state,
        failed: false,
        data: action.payload.prime_offer_callbacks,
      };

    case PRIME_OFFER_CALLBACK_FETCHING_FAILED:
      return {
        ...state,
        failed: 'prime_offer_callbacks',
        data: {},
      };

    default:
      return state;
  }
};
