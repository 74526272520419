import {
  UPDATE_REFUND,
  UNLOAD_REFUND,
  REFUND_FETCHED_SUCCESS,
  RESET_REFUND,
} from '../../constants/actionTypes';

const initialState = {
  refund: [],
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_REFUND:
      return {
        ...state,
        message: action.payload.msg,
      };

    case RESET_REFUND:
      return {
        ...state,
        message: '',
      };

    case REFUND_FETCHED_SUCCESS:
      return {
        ...state,
        refund: action.payload.refunds.map((item) => {
          const newItem = { ...item, id: item.id };
          return newItem;
        }),
        pager: action.payload.pager,
      };

    case UNLOAD_REFUND:
      return initialState;

    default:
      return state;
  }
};
