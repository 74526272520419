import {
  ASYNC_START,
  ASYNC_END,
  LOGIN,
  LOGOUT,
  LOGGED_IN,
  LOGIN_SUCCESSFULL,
  LOGGED_OUT,
  LOGIN_FAILED,
} from '../constants/actionTypes';

const initialState = {
  message: '',
  inProgress: false,
  loggedIn: false,
};

/*
 * reducers for handling auth related events
 */

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        message: action.payload.msg,
        loggedIn: action.payload.status === 200,
      };
    case ASYNC_START:
      if (action.subtype === LOGIN) {
        return { ...state, inProgress: true };
      }
      break;
    case ASYNC_END:
      if (action.subtype === LOGIN) {
        return { ...state, inProgress: false };
      }
      break;
    case LOGGED_IN:
    case LOGIN_SUCCESSFULL:
      return {
        ...state,
        loggedIn: true,
      };
    case LOGIN_FAILED:
      // see if throttling is required on failed login
      break;
    case LOGOUT:
    case LOGGED_OUT:
      return {
        ...state,
        loggedIn: false,
      };
    default:
      return state;
  }

  return state;
};
