import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider, ReactReduxContext } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ConfigProvider } from 'antd';
import validateMessages from './utils/validateMessages';
import * as serviceWorker from './serviceWorker';
import './index.less';
import App from './App';
import configureStore, { history } from './store';

const container = document.getElementById('root');

const root = ReactDOM.createRoot(container);

const store = configureStore({});

root.render(
  <Provider store={store} context={ReactReduxContext}>
    <ConnectedRouter history={history} context={ReactReduxContext}>
      <ConfigProvider
        form={{ validateMessages }}
      >
        <App />
      </ConfigProvider>
    </ConnectedRouter>
  </Provider>,
);

serviceWorker.unregister();
