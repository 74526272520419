import {
  APP_LOADED,
  ASYNC_START,
  ASYNC_END,
  LOGOUT,
  UPDATE_CURRENT_USER,
} from '../constants/actionTypes';

import { getUrl } from '../../routes';

const initialState = {
  appLoaded: false,
  user: null,
  inProgress: false,
  getUrl,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case APP_LOADED:
      return {
        ...state,
        appLoaded: true,
        user: action.user,
      };

    case UPDATE_CURRENT_USER:
      return {
        ...state,
        user: action.user,
      };

    case ASYNC_START:
      return { ...state, inProgress: action.subtype ? action.subtype : true };

    case ASYNC_END:
      return { ...state, inProgress: false };

    case LOGOUT:
      return { ...state, user: null };

    default:
      return state;
  }
};
