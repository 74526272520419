import {
  CREATE_STORE,
  UPDATE_STORE,
  UNLOAD_STORE,
  STORE_LIST_FETCHED_SUCCESS,
  RESET_STORE,
  STORE_FETCHING_SUCCESS,
  STORE_FETCHING_FAILED,
  UPDATE_STORE_DATA,
  PUSHED_STORE,
  API_OFFER_SUCCESS,
  API_OFFER_FAILED,
} from '../constants/actionTypes';
import Network from '../../constants/adNetwork';

const initialState = {
  stores: [],
  apis: {},
  message: '',
  data: {},
  failed: false,
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};

Object.keys(Network).forEach((source) => {
  initialState.apis[source] = [];
});

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_STORE:
    case UPDATE_STORE:
      return {
        ...state,
        message: action.payload.msg,
      };

    case RESET_STORE:
      return {
        ...state,
        message: '',
      };

    case UPDATE_STORE_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.storeData,
        },
      };

    case STORE_FETCHING_SUCCESS:
      return {
        ...state,
        failed: false,
        data: action.payload.store,
      };

    case STORE_FETCHING_FAILED:
      return {
        ...state,
        failed: 'store',
        data: {},
      };

    case STORE_LIST_FETCHED_SUCCESS:
      return {
        ...state,
        data: {},
        stores: action.payload.stores.map((item) => {
          const newItem = { ...item, id: item.id };
          return newItem;
        }),
        pager: action.payload.pager,
      };

    case PUSHED_STORE:
      return {
        ...state,
        stores: state.stores.map((item) => {
          if (item.id === action.payload.id) {
            const newItem = { ...item };
            newItem.pushed_at = action.payload.date;
            return newItem;
          }
          return item;
        }),
      };

    case API_OFFER_SUCCESS:
      return {
        ...state,
        apis: {
          ...state.apis,
          // here source will determine the dynamic keys
          [action.source]: action.payload.apis,
        },
        failed: false,
      };

    case API_OFFER_FAILED:
      return {
        ...state,
        failed: 'api',
        apis: {
          ...state.apis,
          // here source will determine the dynamic keys
          [action.source]: [],
        },
      };

    case UNLOAD_STORE:
      return initialState;

    default:
      return state;
  }
};
