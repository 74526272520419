import { USER_REPORT_FETCHED_SUCCESS } from '../constants/actionTypes';

const initialState = {
  userReports: [],
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
  role: {
    message: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_REPORT_FETCHED_SUCCESS:
      return {
        ...state,
        userReports: action.payload.userReports,
        pager: action.payload.pager,
      };

    default:
      return state;
  }
};
