import axios from 'axios';
import qs from 'qs';
import { pickBy, identity } from 'lodash';

const API_AUTH_ROOT = process.env.REACT_APP_AUTH_API_ROOT;
const API_CMS_ROOT = process.env.REACT_APP_CMS_API_ROOT;
const NEWS_PANEL_ROOT = process.env.REACT_APP_NEWS_PANEL_API_ROOT;

const AJAX_SEARCH_LIMIT = 10;

let token = null;

const config = (options = {}) => ({
  timeout: 100 * 1000,
  params: options.params,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  ...options,
  headers: {
    Authorization: `Bearer ${options.token || token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...options.headers,
  },
});

const responseBody = (res) => res.data;

const makeFormData = (data) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return formData;
};

const authRequest = {
  delete: (url) => axios.delete(`${API_AUTH_ROOT}${url}`, config()).then(responseBody),
  get: (url, options) => axios.get(`${API_AUTH_ROOT}${url}`, config(options)).then(responseBody),
  put: (url, body) => axios.put(`${API_AUTH_ROOT}${url}`, body, config()).then(responseBody),
  post: (url, body) => axios.post(`${API_AUTH_ROOT}${url}`, body, config()).then(responseBody),
  patch: (url, body) => axios.patch(`${API_AUTH_ROOT}${url}`, body, config()).then(responseBody),
};

const cmsRequest = {
  delete: (url) => axios.delete(`${API_CMS_ROOT}${url}`, config()).then(responseBody),
  get: (url, options) => axios.get(`${API_CMS_ROOT}${url}`, config(options)).then(responseBody),
  put: (url, body) => axios.put(`${API_CMS_ROOT}${url}`, body, config()).then(responseBody),
  post: (url, body) => axios.post(`${API_CMS_ROOT}${url}`, body, config()).then(responseBody),
  patch: (url, body) => axios.patch(`${API_CMS_ROOT}${url}`, body, config()).then(responseBody),
};
const cmsExtraRequest = {
  delete: (url) => axios.delete(`${API_CMS_ROOT}${url}`, config()).then(responseBody),
  get: (url, options) => axios.get(`${API_CMS_ROOT}${url}`, config(options)).then(responseBody),
  put: (url, body) => axios.put(`${API_CMS_ROOT}${url}`, body, config()).then(responseBody),
  post: (url, body) => axios.post(`${API_CMS_ROOT}${url}`, body, config()).then(responseBody),
  patch: (url, body) => axios.patch(`${API_CMS_ROOT}${url}`, body, config()).then(responseBody),
};

const articleRequest = {
  delete: (url) => axios.delete(`${NEWS_PANEL_ROOT}${url}`, config()).then(responseBody),
  get: (url, options) => axios.get(`${NEWS_PANEL_ROOT}${url}`, config(options)).then(responseBody),
  put: (url, body) => axios.put(`${NEWS_PANEL_ROOT}${url}`, body, config()).then(responseBody),
  post: (url, body) => axios.post(`${NEWS_PANEL_ROOT}${url}`, body, config()).then(responseBody),
  patch: (url, body) => axios.patch(`${NEWS_PANEL_ROOT}${url}`, body, config()).then(responseBody),
};

const additionalCashbackRequest = {
  delete: (url) => axios.delete(`${API_CMS_ROOT}${url}`, config()).then(responseBody),
  get: (url, options) => axios.get(`${API_CMS_ROOT}${url}`, config(options)).then(responseBody),
  put: (url, body) => axios.put(`${API_CMS_ROOT}${url}`, body, config()).then(responseBody),
  post: (url, body) => axios.post(`${API_CMS_ROOT}${url}`, body, config()).then(responseBody),
  patch: (url, body) => axios.patch(`${API_CMS_ROOT}${url}`, body, config()).then(responseBody),
};

const referralCodeRequest = {
  delete: (url) => axios.delete(`${API_CMS_ROOT}${url}`, config()).then(responseBody),
  get: (url, options) => axios.get(`${API_CMS_ROOT}${url}`, config(options)).then(responseBody),
  put: (url, body) => axios.put(`${API_CMS_ROOT}${url}`, body, config()).then(responseBody),
  post: (url, body) => axios.post(`${API_CMS_ROOT}${url}`, body, config()).then(responseBody),
  patch: (url, body) => axios.patch(`${API_CMS_ROOT}${url}`, body, config()).then(responseBody),
};

const uploadRequest = (file, resource, type, is_image_type, block_type, options) => {
  let postUrl = `${API_CMS_ROOT}/api/common/upload`;
  let metadata = {
    file, resource, type, is_image_type,
  };
  if (type === 'offline_sale_reporting') {
    postUrl = `${API_CMS_ROOT}/api/misc/offline_sales`;
    metadata = { file };
  }
  if (type === 'offline_prime_offer_reporting') {
    postUrl = `${API_CMS_ROOT}/api/misc/offline_prime_offer`;
    metadata = { file };
  }
  if (type === 'offline_share_offer_reporting') {
    postUrl = `${API_CMS_ROOT}/api/misc/offline_share_offer`;
    metadata = { file };
  }
  if (type === 'user_report') {
    postUrl = `${API_CMS_ROOT}/api/misc/block-users?block_type=${block_type}`;
    metadata = { file };
  }

  if (type === 'video_url') {
    postUrl = `${API_CMS_ROOT}/api/common/v2/upload?resource=${resource}&type=${type}`;
    metadata = { file };
  }

  return axios.post(
    postUrl,
    makeFormData(metadata),
    config({ headers: { 'Content-Type': 'multipart/form-data' }, ...options }),
  )
    .then(responseBody);
};
const uploadFile = (file, resource) => axios
  .post(
    `${API_CMS_ROOT}/file-upload`,
    makeFormData({ file, resource }),
    config({ headers: { 'Content-Type': 'multipart/form-data' } }),
  )
  .then(responseBody);

const Auth = {
  currentUser: () => authRequest.get('/auth/user'),
  login: (email, password) => authRequest.post('/auth/login', { email, password }),
  register: (username, email, password) => authRequest.post('/auth/register', { username, email, password }),
  refreshToken: (_token) => authRequest.get('/auth/refresh-token', { token: _token }),
};

const Resource = {
  get: (id) => authRequest.get(`/resources/${id}`),
  list: (params) => authRequest.get('/resources', { params }),
  create: (payload) => authRequest.post('/resources', { ...payload }),
  patch: (id, active) => authRequest.patch(`/resources/${id}`, { active }),
  update: (id) => (payload) => authRequest.put(`/resources/${id}`, { ...payload }),
  search: (query) => authRequest.get('/resources/search', {
    params: { query, limit: AJAX_SEARCH_LIMIT },
  }),
  getResponsibleUser: (resource, action) => authRequest.get('/resources/valid-user', { params: { resource, action } }),
};

const Policy = {
  get: (id) => authRequest.get(`/policies/${id}`),
  list: (params) => authRequest.get('/policies', { params }),
  create: (payload) => authRequest.post('/policies', { ...payload }),
  patch: (id, active) => authRequest.patch(`/policies/${id}`, { active }),
  update: (id) => (payload) => authRequest.put(`/policies/${id}`, { ...payload }),
  search: (query) => authRequest.get('/policies/search', {
    params: { query, limit: AJAX_SEARCH_LIMIT },
  }),
  attachRoles: (id) => (payload) => authRequest.put(`/policies/${id}/roles`, { roles: payload.roles }),
};

const Role = {
  get: (id) => authRequest.get(`/roles/${id}`),
  list: (params) => authRequest.get('/roles', { params }),
  create: (payload) => authRequest.post('/roles', { ...payload }),
  patch: (id, active) => authRequest.patch(`/roles/${id}`, { active }),
  update: (id) => (payload) => authRequest.put(`/roles/${id}`, { ...payload }),
  search: (query) => authRequest.get('/roles/search', {
    params: { query, limit: AJAX_SEARCH_LIMIT },
  }),
  attachUsers: (id) => (payload) => authRequest.put(`/roles/${id}/users`, { users: payload.users }),
};

const User = {
  get: (id) => authRequest.get(`/users/${id}`),
  list: (params) => authRequest.get('/users', { params }),
  create: (payload) => authRequest.post('/users', { ...payload }),
  patch: (id, active) => authRequest.patch(`/users/${id}`, { active }),
  update: (id) => (payload) => authRequest.put(`/users/${id}`, { ...payload }),
  search: (query) => authRequest.get('/users/search', {
    params: { query, limit: AJAX_SEARCH_LIMIT },
  }),
  attachRoles: (id) => (payload) => authRequest.put(`/users/${id}/roles`, { roles: payload.roles }),
  changePassword: (id) => (payload) => authRequest.post(`/users/${id}/change-password`, { ...payload }),
};

const Advertiser = {
  get: (id) => cmsRequest.get(`/api/advertiser/${id}`),
  list: (params) => cmsRequest.get('/api/advertiser', { params }),
  create: (payload) => cmsRequest.post('/api/advertiser', { ...payload }),
  update: (id) => (payload) => cmsRequest.put(`/api/advertiser/${id}`, { ...payload }),
};

const Store = {
  get: (id) => cmsRequest.get(`/api/store/${id}`),
  list: (params) => cmsRequest.get('/api/store', { params }),
  create: (payload) => cmsRequest.post('/api/store', { ...payload }),
  getCategories: (id) => cmsRequest.get(`/api/store/${id}/categories`),
  update: (id) => (payload) => cmsRequest.put(`/api/store/${id}`, { ...payload }),
  generateUrl: (id) => cmsRequest.post(`/api/store/tracking_link/${id}`),
};

const Category = {
  get: (id) => cmsRequest.get(`/api/category/${id}`),
  list: (params) => cmsRequest.get('/api/category', { params }),
  create: (payload) => cmsRequest.post('/api/category', { ...payload }),
  update: (id) => (payload) => cmsRequest.put(`/api/category/${id}`, { ...payload }),
  toggleActive: (id, value) => cmsRequest.patch(`/api/category/${id}`, { ...value }),
};

const SubCategory = {
  get: (id) => cmsRequest.get(`/api/category/sub_category/${id}`),
  list: (params) => cmsRequest.get('/api/category/sub_category', { params }),
  create: (payload) => cmsRequest.post('/api/category/sub_category', { ...payload }),
  update: (id) => (payload) => cmsRequest.put(`/api/category/sub_category/${id}`, { ...payload }),
  toggleActive: (id, value) => cmsRequest.patch(`/api/category/sub_category/${id}`, { ...value }),
};

const Offer = {
  get: (id) => cmsRequest.get(`/api/store/offer/${id}`),
  list: (params) => cmsRequest.get('/api/store/offer', { params }),
  create: (payload) => cmsRequest.post('/api/store/offer', { ...payload }),
  update: (id) => (payload) => cmsRequest.put(`/api/store/offer/${id}`, { ...payload }),
  save: (data) => cmsRequest.post('/api/common/preset', { ...data }),
  presetData: (id) => cmsRequest.get(`/api/common/preset/${id}`),
  toggleActive: (id, value) => cmsRequest.patch(`/api/store/offer/${id}`, { ...value }),
};

const Admitad = {
  getStores: (params) => cmsRequest.get('/admitad/search_stores', { params }),
  connectStore: (payload) => cmsRequest.patch('/admitad/connect', { ...payload }),
};

const Vcommission = {
  getOffers: (params) => cmsRequest.get('/vcomm/search_offer', { params }),
  connectStore: (payload) => cmsRequest.patch('/vcomm/connect', { ...payload }),
};

const PrimeOfferCallbacks = {
  list: (params) => cmsRequest.get('/global-postback/prime-offer', { params }),
  get: (id) => cmsRequest.get(`/global-postback/prime-offer/${id}`),
};

const AdditionalCashback = {
  get: (id) => additionalCashbackRequest.get(`/api/redeem/${id}`),
  list: (params) => additionalCashbackRequest.get('/api/redeem', { params }),
  create: (payload) => additionalCashbackRequest.post('/api/redeem', { ...payload }),
  update: (id) => (payload) => additionalCashbackRequest.put(`/api/redeem/${id}`, { ...payload }),
};

const ReferralCode = {
  update: (payload) => referralCodeRequest.put('/api/refer', { ...payload }),
};

const makeSearchParams = (params) => ({
  params: {
    limit: AJAX_SEARCH_LIMIT,
    ...pickBy(params, identity),
  },
});

const Campaign = {
  get: (id) => cmsRequest.get(`/api/campaign/${id}`),
  list: (params) => cmsRequest.get('/api/campaign', { params }),
  create: (payload) => cmsRequest.post('/api/campaign', { ...payload }),
  update: (id) => (payload) => cmsRequest.put(`/api/campaign/${id}`, { ...payload }),
  toggleActive: (id, value) => cmsRequest.patch(`/api/campaign/${id}`, { ...value }),
};

const cpatCampaign = {
  get: (id) => cmsRequest.get(`/api/campaign/cpat/${id}`),
  list: (params) => cmsRequest.get('/api/campaign/cpat', { params }),
  create: (payload) => cmsRequest.post('/api/campaign/cpat', { ...payload }).catch((err) => console.log(err)),
  update: (id) => (payload) => cmsRequest.put(`/api/campaign/cpat/${id}`, { ...payload }),
  toggleActive: (id, value) => cmsRequest.patch(`/api/campaign/cpat/${id}`, { ...value }),
};

const Influencer = {
  get: (id) => cmsRequest.get(`/api/refer/influencer/${id}`),
  list: (params) => cmsRequest.get('/api/refer/influencer', { params }),
  create: (payload) => cmsRequest.post('/api/refer/influencer', { ...payload }).catch((err) => console.log(err)),
  update: (id) => (payload) => cmsRequest.put(`/api/refer/influencer/${id}`, { ...payload }),
  toggleActive: (id, value) => cmsRequest.patch(`/api/refer/influencer/${id}`, { ...value }),
};

const Search = {
  resource: (query, extra = {}) => authRequest.get('/resources/search', makeSearchParams({ query, ...extra })),
  policy: (query, extra = {}) => authRequest.get('/policies/search', makeSearchParams({ query, ...extra })),
  role: (query, extra = {}) => authRequest.get('/roles/search', makeSearchParams({ query, ...extra })),
  user: (query, extra = {}) => authRequest.get('/users/search', makeSearchParams({ query, ...extra })),

  country: (query, extra = {}) => cmsRequest.get(
    '/api/common/search_country',
    makeSearchParams({ query, ...extra }),
  ),
  language: (query, extra = {}) => cmsRequest.get(
    '/api/common/search_language',
    makeSearchParams({ query, ...extra }),
  ),
  script: (query, extra = {}) => cmsRequest.get(
    '/api/common/search_script',
    makeSearchParams({ query, ...extra }),
  ),
  primeOffers: (query, extra = {}) => cmsRequest.get(
    '/api/prime_offer/search',
    makeSearchParams({ query, ...extra }),
  ),
  state: (query, extra = {}) => cmsRequest.get(
    '/api/common/search_state',
    makeSearchParams({ query, ...extra }),
  ),
  city: (query, extra = {}) => cmsRequest.get(
    '/api/common/search_city',
    makeSearchParams({ query, ...extra }),
  ),
  network: (query, extra = {}) => cmsRequest.get('/search_network', makeSearchParams({ query, ...extra })),
  device: (query, extra = {}) => cmsRequest.get('/search_device', makeSearchParams({ query, ...extra })),

  store: (query, extra = {}) => cmsRequest.get('/api/store/search', makeSearchParams({ query, ...extra })),
  advertiser: (query, extra = {}) => cmsRequest.get(
    '/api/advertiser/search',
    makeSearchParams({ query, ...extra }),
  ),
  category: (query, extra = {}) => cmsRequest.get(
    '/api/category/search',
    makeSearchParams({ query, ...extra }),
  ),
  subcategory: (query, extra = {}) => cmsRequest.get(
    '/api/category/sub_category/search',
    makeSearchParams({ query, ...extra }),
  ),
  tag: (query, extra = {}) => cmsRequest.get(
    '/category/tag/search',
    makeSearchParams({ query, ...extra }),
  ),
  userId: (query, extra = {}) => cmsRequest.get(
    '/tickets/search_by_user',
    makeSearchParams({ query, ...extra }),
  ),
  subject: (query, extra = {}) => cmsRequest.get(
    '/tickets/search_by_subject',
    makeSearchParams({ query, ...extra }),
  ),
  articleCategory: (query, extra = {}) => articleRequest.get(
    '/categories/search',
    makeSearchParams({ query, ...extra }),
  ),
  articleSubscription: (query, extra = {}) => articleRequest.get(
    '/partner/subscription/search',
    makeSearchParams({ query, ...extra }),
  ),
  article: (query) => articleRequest.get('/article/search', {
    params: { query, limit: AJAX_SEARCH_LIMIT },
  }),
  articlePartner: (query) => cmsRequest.get('/partner/search', {
    params: { query, limit: AJAX_SEARCH_LIMIT },
  }),
  programs: (query, extra = {}) => cmsRequest.get('/products/search', makeSearchParams({ query, ...extra })),
  banner: (query, extra = {}) => cmsRequest.get(
    '/api/store/offer/banner/search',
    makeSearchParams({ query, ...extra }),
  ),
  offer: (query, extra = {}) => cmsRequest.get('/api/store/offer/search', makeSearchParams({ query, ...extra })),
  additionalCashback: (id) => additionalCashbackRequest.get(`/api/user/${id}`),
  preset: (query, extra = {}) => cmsRequest.get('/api/common/preset/search', makeSearchParams({ query, ...extra })),
  productName: (query, extra = {}) => cmsRequest.get('/api/product/search', makeSearchParams({ query, ...extra })),
  productStores: (query, extra = {}) => cmsRequest.get('/api/product/product_store/search', makeSearchParams({ query, ...extra })),
  billerCustomCategory: (query, extra = {}) => cmsRequest.get('/api/bill/biller_custom_category/search', makeSearchParams({ query, ...extra })),
  billerCategory: (query, extra = {}) => cmsRequest.get('/api/bill/biller_category/search', makeSearchParams({ query, ...extra })),
  biller: (query, extra = {}) => cmsRequest.get('/api/bill/biller/search', makeSearchParams({ query, ...extra })),
  campaign: (query, extra = {}) => cmsRequest.get(
    '/api/campaign/search',
    makeSearchParams({ query, ...extra }),
  ),
  bannerScript: (query, extra = {}) => cmsExtraRequest.get(
    '/api/common/search_banner_script',
    makeSearchParams({ query, ...extra }),
  ),
  bannerLanguage: (query, extra = {}) => cmsExtraRequest.get(
    '/api/common/search_banner_language',
    makeSearchParams({ query, ...extra }),
  ),
  offerCategory: (query, extra = {}) => cmsExtraRequest.get(
    '/api/share_offer/offer_category',
    makeSearchParams({ query, ...extra }),
  ),
  ShareOffers: (query, extra = {}) => cmsRequest.get(
    '/api/share_offer/search',
    makeSearchParams({ query, ...extra }),
  ),
};

const RemoteConfig = {
  get: (id) => cmsRequest.get(`/api/misc/remote_config/${id}`),
  create: (payload) => cmsRequest.post('/api/misc/remote_config', { ...payload }),
  list: (params) => cmsRequest.get('/api/misc/remote_config', { params }),
  update: (id) => (payload) => cmsRequest.put(`/api/misc/remote_config/${id}`, { ...payload }),
};

const CustomCategory = {
  get: (id) => cmsRequest.get(`/api/store/custom_category/${id}`),
  create: (payload) => cmsRequest.post('/api/store/custom_category', { ...payload }),
  list: (params) => cmsRequest.get('/api/store/custom_category', { params }),
  update: (id) => (payload) => cmsRequest.put(`/api/store/custom_category/${id}`, { ...payload }),
};

const Push = {
  resource: (id, resource) => cmsRequest.post('/push_resource', { id, resource }),
};

const PrimeOffers = {
  get: (id) => cmsRequest.get(`/api/prime_offer/${id}`),
  list: (params) => cmsRequest.get('/api/prime_offer', { params }),
  create: (payload) => cmsRequest.post('/api/prime_offer', { ...payload }),
  update: (id) => (payload) => cmsRequest.put(`/api/prime_offer/${id}`, { ...payload }),
};

const UserReport = {
  list: (params) => cmsRequest.get('/api/misc/block-users', { params }),
};

const OfflineSaleReports = {
  list: (params) => cmsRequest.get('/api/misc/offline_sales', { params }),
};

const OfflinePrimeOfferReports = {
  list: (params) => cmsRequest.get('/api/misc/offline_prime_offer', { params }),
};

const OfflineShareOfferReports = {
  list: (params) => cmsRequest.get('/api/misc/offline_share_offer', { params }),
};

// Payment Dashboard

const CustomBillerCategory = {
  create: (payload) => cmsRequest.post('/api/bill/biller_custom_category', { ...payload }),
  update: (id) => (payload) => cmsRequest.put(`/api/bill/${id}`, { ...payload }),
  get: (id) => cmsRequest.get(`/api/bill/${id}`),
  list: (params) => cmsRequest.get('/api/bill/biller_custom_category', { params }),
};

const Biller = {
  create: (payload) => cmsRequest.post('/api/bill/billers', { ...payload }),
  get: (id) => cmsRequest.get(`/api/bill/biller/${id}`),
  list: (params) => cmsRequest.get('/api/bill/billers', { params }),
  update: (id) => (payload) => cmsRequest.patch(`/api/bill/biller/${id}`, { ...payload }),
};

const BillerCategory = {
  get: (id) => cmsRequest.get(`/api/bill/category/${id}`),
  list: (params) => cmsRequest.get('/api/bill/biller_category', { params }),
  update: (id) => (payload) => cmsRequest.patch(`/api/bill/category/${id}`, { ...payload }),
};

const Refund = {
  get: (id) => cmsRequest.get(`/api/payment_gateway/refund/${id}`),
  list: (params) => cmsRequest.get('/api/payment_gateway/refund', { params }),
  update: (id) => (payload) => cmsRequest.patch(`/api/payment_gateway/refund/${id}`, { ...payload }),
};

const ShareOffers = {
  create: (payload) => cmsExtraRequest.post('/api/share_offer', { ...payload }),
  get: (id) => cmsExtraRequest.get(`/api/share_offer/${id}`),
  list: (params) => cmsExtraRequest.get('/api/share_offer', { params }),
  update: (id) => (payload) => cmsExtraRequest.put(`/api/share_offer/${id}`, { ...payload }),
  clickDashboard: (params) => cmsExtraRequest.get('/api/share_offer/click_dashboard', { params }),
  exportClickDashboard: (params) => cmsExtraRequest.get('/api/share_offer/click_history/csv', { params }),
};

export default {
  Auth,
  Resource,
  Policy,
  Role,
  User,
  Advertiser,
  Store,
  Category,
  SubCategory,
  Offer,
  Search,
  Admitad,
  Vcommission,
  Push,
  RemoteConfig,
  CustomCategory,
  PrimeOfferCallbacks,
  setToken: (_token) => {
    token = _token;
  },
  getToken: () => token,
  upload: uploadRequest,
  uploadUrl: `${API_CMS_ROOT}/upload`,
  uploadFile,
  PrimeOffers,
  AdditionalCashback,
  UserReport,
  OfflineSaleReports,
  OfflinePrimeOfferReports,
  OfflineShareOfferReports,
  ReferralCode,
  CustomBillerCategory,
  Biller,
  BillerCategory,
  Refund,
  Campaign,
  cpatCampaign,
  Influencer,
  ShareOffers,
};
