import React, { PureComponent } from 'react';
import { Popconfirm, Switch } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

class PopConfirm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      active: this.props.status,
    };
  }

  pushToApp = () => {
    const { id, record, tagBlock } = this.props;
    const { active } = this.state;
    record.status = active ? 'ACTIVE' : 'BLOCKED';
    this.setState({ active: !active });
    tagBlock(id, !active);
  };

  render() {
    const { active } = this.state;
    return (
      <Popconfirm
        title="Are you sure to block this campaign？"
        icon={<QuestionCircleOutlined />}
        placement="right"
        okText="Yes"
        cancelText="no"
        onConfirm={this.pushToApp}
      >
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={active}
          size="small"
        />
      </Popconfirm>
    );
  }
}

export default PopConfirm;
