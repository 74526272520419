import {
  CREATE_USER,
  UPDATE_USER,
  ATTACH_ROLE_TO_USER,
  UNLOAD_USER,
  USER_FETCHED_SUCCESS,
  USER_TOGGLE_FAILED,
  USER_TOGGLE_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  users: [],
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
  role: {
    message: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER:
    case UPDATE_USER:
      return {
        ...state,
        message: action.payload.msg,
      };

    case ATTACH_ROLE_TO_USER:
      return {
        ...state,
        role: {
          ...state.role,
          message: action.payload.msg,
        },
      };

    case USER_FETCHED_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        pager: action.payload.pager,
      };

    case USER_TOGGLE_FAILED:
      return state;

    case USER_TOGGLE_SUCCESS:
      return {
        ...state,
        users: state.users.map((user) => {
          if (user.id !== action.payload.id) return user;
          const newUser = { ...user };
          newUser.active = action.payload.active;
          return newUser;
        }),
      };

    case UNLOAD_USER:
      return initialState;

    default:
      return state;
  }
};
